import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppTranslateService } from '@hesti/services/translation/app-translate.service';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

@UntilDestroy()
@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  public constructor(private readonly appTranslateService: AppTranslateService<CommonTranslationKey>) {
    super();
    this.updateTranslation();
    this.appTranslateService.onLangChange.pipe(untilDestroyed(this)).subscribe(this.updateTranslation);
  }

  public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.appTranslateService.instant('paginator.of')} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.appTranslateService.instant('paginator.of')} ${length}`;
  };

  private updateTranslation = (): void => {
    this.itemsPerPageLabel = this.appTranslateService.instant('paginator.itemsPerPage');
    this.firstPageLabel = this.appTranslateService.instant('paginator.firstPage');
    this.lastPageLabel = this.appTranslateService.instant('paginator.lastPage');
    this.nextPageLabel = this.appTranslateService.instant('paginator.nextPage');
    this.previousPageLabel = this.appTranslateService.instant('paginator.previousPage');
  };
}
