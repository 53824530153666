import { COMMON_EN } from 'src/assets/i18n/common-translations/en';

export const EN: PortalTranslations = {
  ...COMMON_EN,
  portalGeneral: {
    openInNewTab: 'Open in new tab',
    poweredBy: 'Powered by',
    disabledThemeToggleTooltip: 'Please close the Appearance settings to switch the theme',
  },
  appearance: {
    title: 'Appearance',
    light: 'Light',
    dark: 'Dark',
    switchingMode: 'Switching mode',
    appearanceHasBeenSuccessfullySaved: 'Appearance has been successfully saved',
    automaticThemeModeInfo: 'Automatic mode changes the theme based on the browser settings.',
    manualThemeModeInfo: 'Manual mode allows users to select a theme in the portal header.',
    general: 'General',
    scroll: 'Scroll',
    spinner: 'Spinner',
    header: 'Header',
    footer: 'Footer',
    palette: 'Palette',
    name: 'Name',

    fontColor: 'Font',
    secondaryFontColor: 'Secondary font',
    bodyBackgroundColor: 'Background',
    dividerColor: 'Divider',
    boxShadowColor: 'Shadow',

    scrollThumbBackgroundColor: 'Scroll',
    scrollThumbHoverBackgroundColor: 'Scroll hover',

    headerBackgroundColor: 'Header',
    headerFontColor: 'Header font',
    headerBorderBottomColor: 'Header border',

    spinnerColor: 'Spinner',

    footerBackgroundColor: 'Footer',
    footerFontColor: 'Footer font',
    footerBorderTopColor: 'Footer border',

    primaryColor: 'Primary',
    accentColor: 'Accent',
    warnColor: 'Warn',
    themeHasBeenSuccessfullyDeleted: 'Theme has been successfully deleted',
  },
  property: {
    editProperty: 'Edit property',
    allPhoto: 'See all',
    numberOfRoomsSingular: 'Room',
    numberOfRoomsPlural: 'Rooms',
    showButton: 'Show all',
    hideButton: 'Hide',
    details: 'Details',

    commonInfo: 'Common Info',
    media: 'Media',
    safety: 'Safety',
    furnitureAndAppliances: 'Furniture and appliances',
    house: 'House',
    building: 'Building',
  },
  companyLogo: {
    logo: 'Logo',
    companyLogoHasBeenSuccessfullyUpdated: 'Company logo has been successfully updated',
    companyLogoInfo: 'The recommended extension is <b>.png with a transparent background</b>.',
  },
};
