export const COMMON_UK: CommonTranslations = {
  api: {
    commonError: {
      badRequest: 'Невірний запит',
      notFound: 'Запитуваний ресурс не знайдено',
      unauthorized: 'Необхідно авторизуватися',
      forbidden: 'На жаль, у вас немає прав для виконання цієї дії',
      internalServerError: 'Внутрішня помилка сервера',
      somethingWentWrong: 'Щось пішло не так :(',
      emailOrPasswordIncorrect: 'Невірний email або пароль',
      emailIsNotConfirmed: 'Email не підтверджено',
      invalidEmailConfirmationToken:
        'Запит на підтвердження не існує або термін його дії закінчився. Будь ласка, знову зверніться за підтвердженням',
      invalidResetPasswordToken:
        'Запит на скидання пароля не існує або термін його дії закінчився. Будь ласка, знову зверніться за скиданням пароля',
      unknownLanguage: 'Невідома мова',
      currentPasswordIncorrect: 'Невірний поточний пароль',
      changePasswordLocked: 'Було 3 невдалих спроби зміни пароля. Будь ласка, зачекайте 15 хвилин і спробуйте знову',
      entityHasBeenModified: 'Запис вже був змінений. Будь ласка, оновіть сторінку і спробуйте знову',
      roleDoesNotExist: 'Роль не існує',
      registrationInvitationLockout: 'Запрошення на реєстрацію заблоковано. Будь ласка, зачекайте 5 хвилин і спробуйте знову',
      invalidRegistrationInvitationToken:
        'Запрошення на реєстрацію не існує або термін його дії закінчився. Будь ласка, зверніться до адміністратора, щоб повторно надіслати запрошення',
      changeEmailLockout: 'Зміна email заблокована. Будь ласка, зачекайте 5 хвилин і спробуйте знову',
      incorrectChangeEmailToken:
        'Запит на зміну email не існує або термін його дії закінчився. Будь ласка, знову зверніться за зміну email',
      incorrectNewEmail: 'Email відрізняється від email, на який було відправлено запит',
      descriptionHasImage: 'Опис використовується для групи зображень',
      addressIsNotFound: 'Запитувана адреса не знайдена',
      userHasBeenArchived: 'Користувач був архівований',
      userHasBeenDeleted: 'Користувач був видалений',
      companyHasBeenArchived: 'Компанія була архівована',
      companyHasBeenDeleted: 'Компанія була видалена',
      userHasAcceptedInvitation: 'Користувач вже прийняв запрошення',
      userIsNotArchived: 'Користувач не архівований',
      themeCannotBeDeleted: 'Тему не можна видалити, оскільки вона використовується',
    },
    validationError: {
      invalidLongitude: 'Недійсна довгота',
      invalidLatitude: 'Недійсна широта',
      invalidValue: 'Недійсне значення',
      required: "Обов'язково до заповнення",
      incorrectEmailFormat: 'Невірний формат email',
      incorrectPasswordFormat: 'Неправильний формат пароля',
      passwordConfirmationDoesntMatch: 'Підтвердження не збігається',
      incorrectUrlFormat: 'Невірний формат email URL',
      maxLength: 'Перевищено максимальну кількість символів ({{currentLength}}/{{maxLength}})',
      maxLengthApiVersion: 'Дозволена кількість символів перевищена',
      maxLengthShort: '{{currentLength}}/{{maxLength}}',
      emailIsAlreadyTaken: 'Цей email вже зареєстрований',
      incorrectFileFormat: 'Невірний формат файлу',
      fileSizeExceedsMaxSize: 'Розмір файлу перевищує максимальний розмір',
      fileNameSizeExceedsMaxSize: '{{fileName}}: розмір файлу перевищує {{size}} Мб',
      fileIsEmpty: 'Файл порожній',
      incorrectPhoneFormat: 'Невірний формат номера телефону',
      incorrectJobTitle: 'Невірний назва посади',
      maximumNumberOfElementsExceeded: 'Перевищено максимальну кількість елементів',
      thereIsDuplicateEmailInTheList: 'У списку є дублікат email адреси',
      invalidCodeFormat: 'Невірний формат коду',
      max: 'Максимальне значення {{max}}',
      min: 'Мінімальне значення {{min}}',
      minStrict: 'Значення повинно бути більше {{min}}',
      maxApiVersion: 'Значення більше максимального',
      minApiVersion: 'Значення менше мінімального',
      mustBeInteger: 'Значення повинно бути цілим числом',
      lessOrEqualThan: 'Значення повинно бути менше або рівне {{comparedNumber}}',
      subDomainIsAlreadyTaken: 'Цей субдомен вже зареєстрований',
      incorrectSubDomainFormat: 'Субдомен неправильний',
      dateThan: 'Дата "До" має бути після дати "Від".',
      atLeastOneImageIsRequired: 'Додайте принаймні одне зображення',
      imageHasNotBeenUploaded: 'Зображення не було завантажено',
      onlyOneLogoIsAllowedPerTheme: 'Дозволено лише один логотип на тему',
    },
  },
  general: {
    language: 'Мова',
    signIn: 'Увійти',
    search: 'Пошук',
    back: 'Назад',
    noResultsFound: 'Список пустий',
    apply: 'Застосувати',
    close: 'Закрити',
    clear: 'Очистити',
    openMail: 'Відкрити пошту',
    startTypingHere: 'Почніть писати тут',
    create: 'Створити',
    save: 'Зберегти',
    cancel: 'Скасувати',
    edit: 'Редагувати',
    refresh: 'Оновити',
    ok: 'Ok',
    min: 'хв.',
    sec: 'сек.',
    change: 'Змінити',
    willBeUnlockedIn: 'Буде розблокований через ',
    noResultsFor: 'Немає результатів для "{{query}}"',
    privacyPolicy: 'Політика Конфіденційності',
    termsOfService: 'Умови Використання',
    allRightsReserved: 'Всі права захищені.',
    numberOfRooms: 'Кількість кімнат',
    price: 'Ціна',
    pricePerSquareMeter: 'Ціна за м²',
    deposit: 'Застава',
    utilityBill: 'ЖКП',
    parking: 'Паркінг',
    area: 'м²',
    areaLabel: 'Площа (м²)',
    landArea: 'Площа ділянки (м²)',
    floor: 'Поверх',
    numberOfFloors: 'Кількість поверхів',
    address: 'Адреса',
    searchAddress: 'Пошук адреси',
    selectedAddress: 'Обрана адреса',
    addressIsNotSelected: 'Адреса не обрана',
    approximateAddress: 'Приблизна адреса',
    country: 'Країна',
    city: 'Місто',
    street: 'Вулиця',
    streetNumber: 'Номер вулиці',
    postalCode: 'Поштовий індекс',
    media: 'Медіа',
    internet: 'Інтернет',
    cableTv: 'Кабельне ТБ',
    telephone: 'Телефон',
    security: 'Безпека',
    antiBurglaryBlinds: 'Протизламні жалюзі',
    antiBurglaryDoorsWindows: 'Протизламні двері/вікна',
    intercom: 'Домофон',
    videoMonitoring: 'Відеонагляд',
    alarmSystem: 'Сигналізація',
    closedArea: 'Закрита територія',
    equipment: 'Обладнання',
    furniture: 'Меблі',
    washingMachine: 'Пральна машина',
    dishwasher: 'Посудомийна машина',
    refrigerator: 'Холодильник',
    fireplace: 'Камін',
    oven: 'Духовка',
    cooker: 'Плита',
    tvSet: 'Телевізор',
    airConditioning: 'Кондиціонер',
    facilities: 'Зручності',
    balcony: 'Балкон',
    loggia: 'Лоджія',
    utilityRoom: 'Кладовка',
    garageOrParkingPlace: 'Гараж/Парковочне місце',
    basement: 'Підвал',
    garden: 'Сад',
    terrace: 'Тераса',
    elevator: 'Ліфт',
    propertyCondition: 'Стан нерухомості',
    heatingType: 'Тип опалення',
    yearOfConstruction: 'Рік будівництва',
    availableFrom: 'Доступно з',
    description: 'Опис',
    location: 'Місцезнаходження',
    setPinOnCenter: 'Встановити пін на центр',
    delete: 'Видалити',
    addDescription: 'Додати опис',
    specify: 'Вказати',
    addPhotoGroup: 'Додати групу фото',
    keyword: 'Ключове слово',
    from: 'Від',
    to: 'До',
    archive: 'Архівувати',
    restore: 'Відновити',
    showTheList: 'Показати список',
    longitude: 'Довгота',
    latitude: 'Широта',
    photoGroup: 'Група фото',
    photo: 'Фото',
    fileHasNotBeenSavedYet: 'Файл ще не збережено',
    warning: 'Попередження',
    maxFileSize: 'Макс. {{size}} Мб',
    noResultsForQueryInThisArea: 'Результатів для запиту "{{query}}" в цій області не знайдено',
    noResultsFoundInThisArea: 'В цій області результатів не знайдено',
    allPhoto: 'Подивитись все',
    backToList: 'Повернутися до списку',
    propertyNotFound: 'Нерухомість не знайдена',
    showOnPortal: 'Показати на порталі',
    author: 'Автор',
    offerType: 'Тип пропозиції',
    currency: 'Валюта',
    marketType: 'Тип ринку',
    propertyType: 'Тип нерухомості',
    detailedInformation: 'Детальна інформація',
    languageSkills: 'Володіння мовами',
  },
  properties: {
    create: 'Створити',
    sort: {
      title: 'Сортування',
      updateAtOption: 'Дата оновлення',
      titleOption: 'Заголовок',
      areaOption: 'Площа',
    },
    filters: {
      title: 'Фільтри',
      resetFilters: 'Скинути',
    },
    showList: 'Показати список',
  },
  paginator: {
    itemsPerPage: 'Елементів на сторінці:',
    firstPage: 'Перша сторінка',
    lastPage: 'Остання сторінка',
    nextPage: 'Наступна сторінка',
    previousPage: 'Попередня сторінка',
    of: 'із',
  },
  portalLanguages: {
    PL: 'Polski',
    EN: 'English',
    RU: 'Русский',
    UK: 'Українська',
  },
  jobTitles: {
    Founder: 'Засновник',
    CoFounder: 'Співзасновник',
    CEO: 'Генеральний директор',
    CFO: 'Фінансовий директор',
    COO: 'Операційний директор',
    Director: 'Директор',
    ViceDirector: 'Заступник директора',
    Manager: 'Менеджер',
    Researcher: 'Дослідник',
    Agent: 'Агент',
    Broker: 'Брокер',
    Investor: 'Інвестор',
    Appraiser: 'Оцінювач',
    Consultant: 'Консультант',
    Contractor: 'Підрядник',
    Inspector: 'Інспектор',
    Clerk: 'Клерк',
    Analyst: 'Аналітик',
    Financier: 'Фінансист',
    Marketer: 'Маркетолог',
    Administrator: 'Адміністратор',
    Assistant: 'Асистент',
    Specialist: 'Спеціаліст',
    Expert: 'Експерт',
    Realtor: 'Ріелтор',
  },
  passwordRules: {
    length: 'Мінімум 8 символів',
    upperCase: 'Два символи верхнього регістру (A-Z)',
    lowerCase: 'Три рядкові символи (a-z)',
    numerals: 'Два числа (0-9)',
    specialCharacter: 'Один спеціальний символ (!@#$&*)',
    allowedCharacters: 'Лише дозволені символи (A-Z, a-z, 0-9, !@#$&*)',
  },
  marketTypes: {
    PrimaryMarket: 'Первинний ринок',
    SecondaryMarket: 'Вторинний ринок',
  },
  offerTypes: {
    ForRent: 'В оренду',
    ForSale: 'На продаж',
  },
  propertyTypes: {
    Apartment: 'Квартира',
    Room: 'Кімната',
    House: 'Будинок',
    Garage: 'Гараж',
    CommercialPremises: 'Комерційна нерухомість',
    Land: 'Ділянка',
  },
  heatingTypes: {
    NoHeating: 'Без опалення',
    CentralHeating: 'Центральне опалення',
    ElectricHeating: 'Електричне опалення',
    FloorHeating: 'Підлогове опалення',
    GasHeating: 'Газове опалення',
    HeatPump: 'Тепловий насос',
    StoveHeating: 'Пічне опалення',
    BoilerHeating: 'Котельне опалення',
    Other: 'Інше',
  },
  propertyConditions: {
    RoughFinish: 'Чорнове оздоблення',
    FineFinish: 'Чистове оздоблення',
    RenovationWithoutFurniture: 'Ремонт без меблів',
    RenovationWithFurniture: 'Ремонт з меблями',
  },
  shortcuts: {
    esc: 'Esc',
    arrowLeft: '←',
    arrowRight: '→',
    shiftQ: 'Alt + Shift + Q',
    shiftN: 'Alt + Shift + N',
    shiftS: 'Alt + Shift + S',
    shiftF: 'Alt + Shift + F',
  },
  loading: 'Завантаження...',
  viewModes: {
    MapList: 'Карта зі списком',
    Map: 'Карта',
  },
  deleteDialog: {
    info: 'Введіть ключове слово "{{keyword}}", щоб видалити.',
  },
  countries: {
    AF: 'Афганістан',
    AL: 'Албанія',
    DZ: 'Алжир',
    AD: 'Андорра',
    AO: 'Ангола',
    AG: 'Антигуа і Барбуда',
    AR: 'Аргентина',
    AM: 'Вірменія',
    AU: 'Австралія',
    AT: 'Австрія',
    AZ: 'Азербайджан',
    BS: 'Багами',
    BH: 'Бахрейн',
    BD: 'Бангладеш',
    BB: 'Барбадос',
    BY: 'Білорусь',
    BE: 'Бельгія',
    BZ: 'Беліз',
    BJ: 'Бенін',
    BT: 'Бутан',
    BO: 'Болівія',
    BA: 'Боснія і Герцеговина',
    BW: 'Ботсвана',
    BR: 'Бразилія',
    BN: 'Бруней',
    BG: 'Болгарія',
    BF: 'Буркіна-Фасо',
    BI: 'Бурунді',
    CI: "Кот-д'Івуар",
    CV: 'Кабо-Верде',
    KH: 'Камбоджа',
    CM: 'Камерун',
    CA: 'Канада',
    CF: 'Центральноафриканська Республіка',
    TD: 'Чад',
    CL: 'Чилі',
    CN: 'Китай',
    CO: 'Колумбія',
    KM: 'Коморські Острови',
    CG: 'Конго',
    CR: 'Коста-Рика',
    HR: 'Хорватія',
    CU: 'Куба',
    CY: 'Кіпр',
    CZ: 'Чехія',
    CD: 'Демократична Республіка Конго',
    DK: 'Данія',
    DJ: 'Джибуті',
    DM: 'Домініка',
    DO: 'Домініканська Республіка',
    EC: 'Еквадор',
    EG: 'Єгипет',
    SV: 'Сальвадор',
    GQ: 'Екваторіальна Гвінея',
    ER: 'Еритрея',
    EE: 'Естонія',
    SZ: 'Есватіні',
    ET: 'Ефіопія',
    FJ: 'Фіджі',
    FI: 'Фінляндія',
    FR: 'Франція',
    GA: 'Габон',
    GM: 'Гамбія',
    GE: 'Грузія',
    DE: 'Німеччина',
    GH: 'Гана',
    GR: 'Греція',
    GD: 'Гренада',
    GT: 'Гватемала',
    GN: 'Гвінея',
    GW: 'Гвінея-Бісау',
    GY: 'Гаяна',
    HT: 'Гаїті',
    VA: 'Папський Престол',
    HN: 'Гондурас',
    HU: 'Угорщина',
    IS: 'Ісландія',
    IN: 'Індія',
    ID: 'Індонезія',
    IR: 'Іран',
    IQ: 'Ірак',
    IE: 'Ірландія',
    IL: 'Ізраїль',
    IT: 'Італія',
    JM: 'Ямайка',
    JP: 'Японія',
    JO: 'Йорданія',
    KZ: 'Казахстан',
    KE: 'Кенія',
    KI: 'Кірибаті',
    KW: 'Кувейт',
    KG: 'Киргизстан',
    LA: 'Лаос',
    LV: 'Латвія',
    LB: 'Ліван',
    LS: 'Лесото',
    LR: 'Ліберія',
    LY: 'Лівія',
    LI: 'Ліхтенштейн',
    LT: 'Литва',
    LU: 'Люксембург',
    MG: 'Мадагаскар',
    MW: 'Малаві',
    MY: 'Малайзія',
    MV: 'Мальдіви',
    ML: 'Малі',
    MT: 'Мальта',
    MH: 'Маршаллові Острови',
    MR: 'Мавританія',
    MU: 'Маврикій',
    MX: 'Мексика',
    FM: 'Мікронезія',
    MD: 'Молдова',
    MC: 'Монако',
    MN: 'Монголія',
    ME: 'Чорногорія',
    MA: 'Марокко',
    MZ: 'Мозамбік',
    MM: "М'янма",
    NA: 'Намібія',
    NR: 'Науру',
    NP: 'Непал',
    NL: 'Нідерланди',
    NZ: 'Нова Зеландія',
    NI: 'Нікарагуа',
    NE: 'Нігер',
    NG: 'Нігерія',
    KP: 'Північна Корея',
    MK: 'Північна Македонія',
    NO: 'Норвегія',
    OM: 'Оман',
    PK: 'Пакистан',
    PW: 'Палау',
    PS: 'Держава Палестина',
    PA: 'Панама',
    PG: 'Папуа-Нова Гвінея',
    PY: 'Парагвай',
    PE: 'Перу',
    PH: 'Філіппіни',
    PL: 'Польща',
    PT: 'Португалія',
    QA: 'Катар',
    RO: 'Румунія',
    RU: 'Росія',
    RW: 'Руанда',
    KN: 'Сент-Кітс і Невіс',
    LC: 'Сент-Люсія',
    VC: 'Сент-Вінсент і Гренадини',
    WS: 'Самоа',
    SM: 'Сан-Марино',
    ST: 'Сан-Томе і Принсіпі',
    SA: 'Саудівська Аравія',
    SN: 'Сенегал',
    RS: 'Сербія',
    SC: 'Сейшельські Острови',
    SL: 'Сьєрра-Леоне',
    SG: 'Сінгапур',
    SK: 'Словаччина',
    SI: 'Словенія',
    SB: 'Соломонові Острови',
    SO: 'Сомалі',
    ZA: 'Південна Африка',
    KR: 'Південна Корея',
    SS: 'Південний Судан',
    ES: 'Іспанія',
    LK: 'Шрі-Ланка',
    SD: 'Судан',
    SR: 'Суринам',
    SE: 'Швеція',
    CH: 'Швейцарія',
    SY: 'Сирія',
    TJ: 'Таджикистан',
    TZ: 'Танзанія',
    TH: 'Таїланд',
    TL: 'Східний Тимор',
    TG: 'Того',
    TO: 'Тонга',
    TT: 'Тринідад і Тобаго',
    TN: 'Туніс',
    TR: 'Туреччина',
    TM: 'Туркменістан',
    TV: 'Тувалу',
    UG: 'Уганда',
    UA: 'Україна',
    AE: 'Обʼєднані Арабські Емірати',
    GB: 'Сполучене Королівство',
    US: 'Сполучені Штати Америки',
    UY: 'Уругвай',
    UZ: 'Узбекистан',
    VU: 'Вануату',
    VE: 'Венесуела',
    VN: 'Вʼєтнам',
    YE: 'Ємен',
    ZM: 'Замбія',
    ZW: 'Зімбабве',
  },
  currencies: {
    USD: '$',
    EUR: '€',
    UAH: '₴',
    CZK: 'Kč',
    PLN: 'zł',
  },
  unsavedChanges: {
    title: 'Незбережені зміни',
    question: 'У вас є незбережені зміни. Що бажаєте зробити?',
    cancel: 'Залишитись',
    leave: 'Залишити',
    discard: 'Відхилити',
    save: 'Зберегти',
  },
  themeModes: {
    Light: 'Світлий',
    Dark: 'Темний',
    Browser: 'Автоматичний',
    Select: 'Ручний',
  },
};
