import { ErrorCode } from '@hesti/constants/error-code.const';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export const errorCodeTranslations: Record<ErrorCode, CommonTranslationKey> = {
  [ErrorCode.BadRequest]: 'api.commonError.badRequest',
  [ErrorCode.NotFound]: 'api.commonError.notFound',
  [ErrorCode.Unauthorized]: 'api.commonError.unauthorized',
  [ErrorCode.Forbidden]: 'api.commonError.forbidden',
  [ErrorCode.InternalServerError]: 'api.commonError.internalServerError',
  [ErrorCode.SomethingWentWrong]: 'api.commonError.somethingWentWrong',
};
