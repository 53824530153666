import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class RouterScrollService {
  private lastRoute: string;

  public constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public scrollToTopOnRouterChange(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url.split('?')[0];
        if (currentRoute !== this.lastRoute) {
          this.document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
          this.lastRoute = currentRoute;
        }
      }
    });
  }
}
