import { Injectable } from '@angular/core';
import { LanguageState } from '@portal/store/language/language.state';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { Observable, of } from 'rxjs';
import { CompanyInfoState } from '@portal/store/company-info/company-info.state';
import { PropertyPreviewModel } from '@hesti/models/property/property-preview/property-preview.model';
import { AppRoutes } from '@hesti/constants/app-routes.const';
import { BasePropertiesService } from '@hesti/features/properties/services/base-properties.service';

@Injectable()
export class PropertiesService extends BasePropertiesService {
  public language$: Observable<PortalLanguage> = this.store.select(LanguageState.language);
  public stateDependencies: Observable<void> = of(undefined);

  public get isAdmin(): boolean {
    return false;
  }

  public get companyId(): string {
    return this.store.selectSnapshot(CompanyInfoState.companyGeneralInfo)!.id;
  }

  public openProperty(property: PropertyPreviewModel): void {
    this.router.navigate([AppRoutes.Portal.Property.getLink(property.id)]);
  }
}
