import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SheetService } from '@hesti/services/sheet/sheet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Icon } from '@hesti/components/icon/icon.enum';
import { SheetComponentModel } from '@hesti/services/sheet/sheet-component.model';
import { AnimationsConst } from '@hesti/constants/animations.const';
import { NgFor, AsyncPipe } from '@angular/common';
import { SheetHostDirective } from './sheet-host.directive';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ha-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [AnimationsConst.SlideIn(), AnimationsConst.SlideOut()],
  imports: [NgFor, SheetHostDirective, AsyncPipe],
})
export class SheetComponent implements OnInit, AfterViewInit {
  public readonly Icon = Icon;

  public constructor(
    public readonly sheetService: SheetService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.sheetService.sheetComponents$.pipe(untilDestroyed(this)).subscribe(() => this.changeDetector.detectChanges());
  }

  public trackByName(_: number, { component }: SheetComponentModel): string {
    return component.name;
  }

  public ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }
}
