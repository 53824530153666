import { COMMON_PL } from 'src/assets/i18n/common-translations/pl';

export const PL: PortalTranslations = {
  ...COMMON_PL,
  portalGeneral: {
    openInNewTab: 'Otwórz w nowej karcie',
    poweredBy: 'Przy wsparciu',
    disabledThemeToggleTooltip: 'Proszę zamknąć ustawienia wyglądu, aby zmienić motyw',
  },
  appearance: {
    title: 'Wygląd',
    light: 'Jasny',
    dark: 'Ciemny',
    switchingMode: 'Przełączanie trybu',
    appearanceHasBeenSuccessfullySaved: 'Wygląd został pomyślnie zapisany',
    automaticThemeModeInfo: 'Automatyczny tryb zmienia motyw na podstawie ustawień przeglądarki.',
    manualThemeModeInfo: 'Tryb ręczny pozwala użytkownikom wybrać motyw w nagłówku portalu.',
    general: 'Ogólne',
    scroll: 'Przewijanie',
    spinner: 'Spinner',
    header: 'Nagłówek',
    footer: 'Stopka',
    palette: 'Paleta',
    name: 'Nazwa',

    fontColor: 'Czcionka',
    secondaryFontColor: 'Czcionka pomocnicza',
    bodyBackgroundColor: 'Tło',
    dividerColor: 'Podziałka',
    boxShadowColor: 'Cień',

    scrollThumbBackgroundColor: 'Przewijanie',
    scrollThumbHoverBackgroundColor: 'Przewijanie po najechaniu',

    headerBackgroundColor: 'Nagłówek',
    headerFontColor: 'Czcionka nagłówka',
    headerBorderBottomColor: 'Granica nagłówka',

    spinnerColor: 'Spinner',

    footerBackgroundColor: 'Stopka',
    footerFontColor: 'Czcionka stopki',
    footerBorderTopColor: 'Granica stopki',

    primaryColor: 'Podstawowy',
    accentColor: 'Akcent',
    warnColor: 'Ostrzeżenie',
    themeHasBeenSuccessfullyDeleted: 'Motyw został pomyślnie usunięty',
  },
  property: {
    editProperty: 'Edytuj nieruchomość',
    allPhoto: 'Widzieć wszystko',
    numberOfRoomsSingular: 'Liczba pokoi',
    numberOfRoomsPlural: 'Liczba pokoi',
    showButton: 'Pokaż wszystko',
    hideButton: 'Schowaj',
    details: 'Bliższe dane',

    commonInfo: 'Wspólne informacje',
    media: 'Głoska bezdźwięczna',
    safety: 'Bezpieczeństwo',
    furnitureAndAppliances: 'Meble i urządzenia',
    house: 'Dom',
    building: 'Budynek',
  },
  companyLogo: {
    logo: 'Logo',
    companyLogoHasBeenSuccessfullyUpdated: 'Logo firmy zostało pomyślnie zaktualizowane',
    companyLogoInfo: 'Zalecane rozszerzenie to <b>.png z przezroczystym tłem</b>.',
  },
};
