import { COMMON_RU } from 'src/assets/i18n/common-translations/ru';

export const RU: PortalTranslations = {
  ...COMMON_RU,
  portalGeneral: {
    openInNewTab: 'Открыть в новой вкладке',
    poweredBy: 'При поддержке',
    disabledThemeToggleTooltip: 'Пожалуйста, закройте настройки оформления, чтобы изменить тему',
  },
  appearance: {
    title: 'Оформление',
    light: 'Светлая',
    dark: 'Темная',
    switchingMode: 'Режим переключения',
    appearanceHasBeenSuccessfullySaved: 'Оформление успешно сохранено',
    automaticThemeModeInfo: 'Автоматический режим меняет тему на основе настроек браузера.',
    manualThemeModeInfo: 'Ручной режим позволяет пользователям выбрать тему в шапке портала.',
    name: 'Название',
    general: 'Общее',
    scroll: 'Прокрутка',
    spinner: 'Спиннер',
    header: 'Шапка',
    footer: 'Футтер',
    palette: 'Палитра',

    fontColor: 'Шрифт',
    secondaryFontColor: 'Вторичный шрифт',
    bodyBackgroundColor: 'Фон',
    dividerColor: 'Разделитель',
    boxShadowColor: 'Тень',

    scrollThumbBackgroundColor: 'Прокрутка',
    scrollThumbHoverBackgroundColor: 'Прокрутка при наведении',

    headerBackgroundColor: 'Шапка',
    headerFontColor: 'Шрифт шапки',
    headerBorderBottomColor: 'Граница шапки',

    spinnerColor: 'Спиннер',

    footerBackgroundColor: 'Футтер',
    footerFontColor: 'Шрифт футтера',
    footerBorderTopColor: 'Граница футтера',

    primaryColor: 'Основной',
    accentColor: 'Акцент',
    warnColor: 'Предупреждение',
    themeHasBeenSuccessfullyDeleted: 'Тема успешно удалена',
  },
  property: {
    editProperty: 'Редактировать недвижимость',
    allPhoto: 'Посмотреть все',
    numberOfRoomsSingular: 'Количество комнат',
    numberOfRoomsPlural: 'Количество комнат',
    showButton: 'Показать все',
    hideButton: 'Скрыть',
    details: 'Подробности',

    commonInfo: 'Общая информация',
    media: 'Медиа',
    safety: 'Безопасность',
    furnitureAndAppliances: 'Мебель и техника',
    house: 'Дом',
    building: 'Здание',
  },
  companyLogo: {
    logo: 'Логотип',
    companyLogoHasBeenSuccessfullyUpdated: 'Логотип компании успешно обновлен',
    companyLogoInfo: 'Рекомендуемое расширение - <b>.png с прозрачным фоном</b>.',
  },
};
