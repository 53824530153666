export const COMMON_EN: CommonTranslations = {
  api: {
    commonError: {
      badRequest: 'Bad request',
      notFound: 'The requested resource could not be found',
      unauthorized: 'You need to login',
      forbidden: "Unfortunately you don't have permission to perform this action",
      internalServerError: 'Internal server error',
      somethingWentWrong: 'Oops... Something went wrong :(',
      emailOrPasswordIncorrect: 'Email or password is incorrect',
      emailIsNotConfirmed: 'Email is not confirmed',
      invalidEmailConfirmationToken: 'The confirmation request does not exist or has expired. Please request a new confirmation again',
      invalidResetPasswordToken: 'The password reset request does not exist or has expired. Please request a new password reset again',
      unknownLanguage: 'Unknown language',
      currentPasswordIncorrect: 'Current password is incorrect',
      changePasswordLocked: 'There were 3 unsuccessful attempts to change the password. Please wait 15 minutes and try again',
      entityHasBeenModified: 'The entity already has been modified. Please refresh the page and try again',
      roleDoesNotExist: 'Role does not exist',
      registrationInvitationLockout: 'The registration invitation is locked. Please wait 5 minutes and try again',
      invalidRegistrationInvitationToken:
        'The registration invitation does not exist or has expired. Please contact the administrator to resend the invitation',
      changeEmailLockout: 'The email change is locked. Please wait 5 minutes and try again',
      incorrectChangeEmailToken: 'The email change request does not exist or has expired. Please request a new email change again',
      incorrectNewEmail: 'The email is different from the email to which the request was sent',
      descriptionHasImage: 'Description is used for an image group',
      addressIsNotFound: 'The requested address was not found',
      userHasBeenArchived: 'User has been archived',
      userHasBeenDeleted: 'User has been deleted',
      companyHasBeenArchived: 'Company has been archived',
      companyHasBeenDeleted: 'Company has been deleted',
      userHasAcceptedInvitation: 'User has accepted the invitation',
      userIsNotArchived: 'User is not archived',
      themeCannotBeDeleted: 'The theme cannot be deleted because it is in use',
    },
    validationError: {
      invalidLongitude: 'Invalid longitude',
      invalidLatitude: 'Invalid latitude',
      invalidValue: 'Invalid value',
      required: 'Required to fill out',
      incorrectEmailFormat: 'Email is not correct',
      incorrectPasswordFormat: 'Password is not correct',
      passwordConfirmationDoesntMatch: "The confirmation doesn't match",
      incorrectUrlFormat: 'URL is not correct',
      maxLength: 'The maximum number of characters has been exceeded ({{currentLength}}/{{maxLength}})',
      maxLengthApiVersion: 'The allowed number of characters has been exceeded',
      maxLengthShort: '{{currentLength}}/{{maxLength}}',
      emailIsAlreadyTaken: 'This email is already registered',
      incorrectFileFormat: 'Invalid file format',
      fileSizeExceedsMaxSize: 'File size exceeds max size',
      fileNameSizeExceedsMaxSize: '{{fileName}}: file size exceeds {{size}} Mb',
      fileIsEmpty: 'File is empty',
      incorrectPhoneFormat: 'Phone number is not correct',
      incorrectJobTitle: 'Job title is not correct',
      maximumNumberOfElementsExceeded: 'Maximum number of elements exceeded',
      thereIsDuplicateEmailInTheList: 'There is a duplicate email in the list',
      invalidCodeFormat: 'Invalid code format',
      max: 'Maximum value is {{max}}',
      min: 'Minimum value is {{min}}',
      minStrict: 'The value must be greater than {{min}}',
      maxApiVersion: 'Value greater than maximum',
      minApiVersion: 'Value less than minimum',
      mustBeInteger: 'The value must be an integer',
      lessOrEqualThan: 'The value must be less than or equal to {{comparedNumber}}',
      subDomainIsAlreadyTaken: 'This subdomain is already registered',
      incorrectSubDomainFormat: 'Subdomain is not correct',
      dateThan: '"To" date must be after "From" date',
      atLeastOneImageIsRequired: 'At least one image is required',
      imageHasNotBeenUploaded: 'The image has not been uploaded',
      onlyOneLogoIsAllowedPerTheme: 'Only one logo is allowed per theme',
    },
  },
  general: {
    language: 'Language',
    signIn: 'Sign In',
    search: 'Search',
    back: 'Back',
    noResultsFound: 'The list is empty',
    apply: 'Apply',
    close: 'Close',
    clear: 'Clear',
    openMail: 'Open mail',
    startTypingHere: 'Start typing here',
    create: 'Create',
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    refresh: 'Refresh',
    ok: 'Ok',
    min: 'min.',
    sec: 'sec.',
    change: 'Change',
    willBeUnlockedIn: 'Will be unlocked in ',
    noResultsFor: 'No results for "{{query}}"',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    allRightsReserved: 'All rights reserved.',
    numberOfRooms: 'Number of rooms',
    price: 'Price',
    pricePerSquareMeter: 'Price per m²',
    deposit: 'Pledge',
    utilityBill: 'Utility bill',
    parking: 'Parking',
    area: 'm²',
    areaLabel: 'Area (m²)',
    landArea: 'Land area (m²)',
    floor: 'Floor',
    numberOfFloors: 'Number of floors',
    address: 'Address',
    searchAddress: 'Search address',
    selectedAddress: 'Selected address',
    addressIsNotSelected: 'Address is not selected',
    approximateAddress: 'Approximate address',
    country: 'Country',
    city: 'City',
    street: 'Street',
    streetNumber: 'Street number',
    postalCode: 'Postal code',
    media: 'Media',
    internet: 'Internet',
    cableTv: 'Cable TV',
    telephone: 'Telephone',
    security: 'Security',
    antiBurglaryBlinds: 'Anti-burglary blinds',
    antiBurglaryDoorsWindows: 'Anti-burglary doors/windows',
    intercom: 'Intercom',
    videoMonitoring: 'Video monitoring',
    alarmSystem: 'Alarm system',
    closedArea: 'Closed area',
    equipment: 'Equipment',
    furniture: 'Furniture',
    washingMachine: 'Washing machine',
    dishwasher: 'Dishwasher',
    refrigerator: 'Refrigerator',
    fireplace: 'Fireplace',
    oven: 'Oven',
    cooker: 'Cooker',
    tvSet: 'TV set',
    airConditioning: 'Air conditioning',
    facilities: 'Facilities',
    balcony: 'Balcony',
    loggia: 'Loggia',
    utilityRoom: 'Utility room',
    garageOrParkingPlace: 'Garage or parking place',
    basement: 'Basement',
    garden: 'Garden',
    terrace: 'Terrace',
    elevator: 'Elevator',
    propertyCondition: 'Property condition',
    heatingType: 'Heating type',
    yearOfConstruction: 'Year of construction',
    availableFrom: 'Available from',
    description: 'Description',
    location: 'Location',
    setPinOnCenter: 'Set pin on center',
    delete: 'Delete',
    addDescription: 'Add description',
    specify: 'Specify',
    addPhotoGroup: 'Add photo group',
    keyword: 'Keyword',
    from: 'From',
    to: 'To',
    archive: 'Archive',
    restore: 'Restore',
    showTheList: 'Show the list',
    longitude: 'Longitude',
    latitude: 'Latitude',
    photoGroup: 'Photo group',
    photo: 'Photo',
    fileHasNotBeenSavedYet: 'File has not been saved yet',
    warning: 'Warning',
    maxFileSize: 'Max. {{size}} Mb',
    noResultsForQueryInThisArea: 'No results for "{{query}}" in this area',
    noResultsFoundInThisArea: 'No results found in this area',
    allPhoto: 'See all',
    backToList: 'Back to the list',
    propertyNotFound: 'Property not found',
    showOnPortal: 'Show on Portal',
    author: 'Author',
    offerType: 'Offer type',
    currency: 'Currency',
    marketType: 'Market type',
    propertyType: 'Property type',
    detailedInformation: 'Detailed information',
    languageSkills: 'Language skills',
  },
  properties: {
    create: 'Create',
    sort: {
      title: 'Sort',
      updateAtOption: 'Update date',
      titleOption: 'Title',
      areaOption: 'Area',
    },
    filters: {
      title: 'Filters',
      resetFilters: 'Reset',
    },
    showList: 'Show list',
  },
  paginator: {
    itemsPerPage: 'Items per page:',
    firstPage: 'First page',
    lastPage: 'Last page',
    nextPage: 'Next page',
    previousPage: 'Previous page',
    of: 'of',
  },
  portalLanguages: {
    PL: 'Polski',
    RU: 'Русский',
    EN: 'English',
    UK: 'Українська',
  },
  jobTitles: {
    Founder: 'Founder',
    CoFounder: 'Co-founder',
    CEO: 'CEO',
    CFO: 'CFO',
    COO: 'COO',
    Director: 'Director',
    ViceDirector: 'Vice director',
    Manager: 'Manager',
    Researcher: 'Researcher',
    Agent: 'Agent',
    Broker: 'Broker',
    Investor: 'Investor',
    Appraiser: 'Appraiser',
    Consultant: 'Consultant',
    Contractor: 'Contractor',
    Inspector: 'Inspector',
    Clerk: 'Clerk',
    Analyst: 'Analyst',
    Financier: 'Financier',
    Marketer: 'Marketer',
    Administrator: 'Administrator',
    Assistant: 'Assistant',
    Specialist: 'Specialist',
    Expert: 'Expert',
    Realtor: 'Realtor',
  },
  passwordRules: {
    length: '8 characters minimum',
    upperCase: 'Two uppercase characters (A-Z)',
    lowerCase: 'Three lowercase characters (a-z)',
    numerals: 'Two numbers (0-9)',
    specialCharacter: 'One special character (!@#$&*)',
    allowedCharacters: 'Only allowed characters (A-Z, a-z, 0-9, !@#$&*)',
  },
  marketTypes: {
    PrimaryMarket: 'Primary market',
    SecondaryMarket: 'Secondary market',
  },
  offerTypes: {
    ForRent: 'For rent',
    ForSale: 'For sale',
  },
  propertyTypes: {
    Apartment: 'Apartment',
    Room: 'Room',
    House: 'House',
    Garage: 'Garage',
    CommercialPremises: 'Commercial premises',
    Land: 'Land',
  },
  heatingTypes: {
    NoHeating: 'No heating',
    CentralHeating: 'Central heating',
    ElectricHeating: 'Electric heating',
    FloorHeating: 'Floor heating',
    GasHeating: 'Gas heating',
    HeatPump: 'Heat pump',
    StoveHeating: 'Stove heating',
    BoilerHeating: 'Boiler heating',
    Other: 'Other',
  },
  propertyConditions: {
    RoughFinish: 'Rough finish',
    FineFinish: 'Fine finish',
    RenovationWithoutFurniture: 'Renovation without furniture',
    RenovationWithFurniture: 'Renovation with furniture',
  },
  shortcuts: {
    esc: 'Esc',
    arrowLeft: '←',
    arrowRight: '→',
    shiftQ: 'Alt + Shift + Q',
    shiftN: 'Alt + Shift + N',
    shiftS: 'Alt + Shift + S',
    shiftF: 'Alt + Shift + F',
  },
  loading: 'Loading...',
  viewModes: {
    MapList: 'Map with list',
    Map: 'Map',
  },
  deleteDialog: {
    info: 'Enter the keyword "{{keyword}}" to delete.',
  },
  countries: {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AD: 'Andorra',
    AO: 'Angola',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CI: "Côte d'Ivoire",
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GR: 'Greece',
    GD: 'Grenada',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    VA: 'Vatican City',
    HN: 'Honduras',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MR: 'Mauritania',
    MU: 'Mauritius',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    KP: 'North Korea',
    MK: 'North Macedonia',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'State of Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PL: 'Poland',
    PT: 'Portugal',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  currencies: {
    USD: '$',
    EUR: '€',
    UAH: '₴',
    CZK: 'Kč',
    PLN: 'zł',
  },
  unsavedChanges: {
    title: 'Unsaved changes',
    question: 'You have unsaved changes. What would you like to do?',
    cancel: 'Stay',
    leave: 'Leave',
    discard: 'Discard',
    save: 'Save',
  },
  themeModes: {
    Light: 'Light',
    Dark: 'Dark',
    Browser: 'Automatic',
    Select: 'Manual',
  },
};
