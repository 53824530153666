import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap } from 'rxjs';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { ApiConst } from '@hesti/constants/api.const';
import { ManagePortalPath } from '@portal/constants/api.const';
import { ClientMessagingService } from '@hesti/services/client-messaging/client-messaging.service';
import { RefreshTokenRequestClientMessageModel } from '@hesti/models/client-messaging/refresh-token-request-client-message.model';
import { JwtInfoClientMessageModel } from '@hesti/models/client-messaging/jwt-info-client-message.model';
import { Store } from '@ngxs/store';
import { UserStateActions } from '@hesti/store/user/user.state.actions';
import { UserState } from '@hesti/store/user/user.state';
import { environment } from '../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationInterceptor implements HttpInterceptor {
  public constructor(
    private readonly clientMessagingService: ClientMessagingService,
    private readonly store: Store,
  ) {
    this.clientMessagingService.init(window.parent, environment.adminUrl);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isUnauthorizedRequest(request)) {
      return next.handle(request);
    }

    request = this.addBearerToken(request);
    return next
      .handle(request)
      .pipe(catchError((httpErrorResponse: HttpErrorResponse) => this.handleUnauthorizedError(request, next, httpErrorResponse)));
  }

  private isUnauthorizedRequest(request: HttpRequest<unknown>): boolean {
    return !this.isRequestToFileUpload(request) && !this.isRequestToManagePortal(request);
  }

  private isRequestToFileUpload(request: HttpRequest<unknown>): boolean {
    return request.url.includes(ApiConst.Storage.UploadFile);
  }

  private isRequestToManagePortal(request: HttpRequest<unknown>): boolean {
    return request.url.includes(ManagePortalPath);
  }

  private addBearerToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken = this.store.selectSnapshot(UserState.accessToken);
    if (accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return request;
  }

  private handleUnauthorizedError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> | never {
    if (httpErrorResponse.status === ErrorCode.Unauthorized) {
      return this.refreshToken(request, next);
    }

    throw httpErrorResponse;
  }

  private refreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | never {
    this.clientMessagingService.sendMessage(new RefreshTokenRequestClientMessageModel(true));
    return this.clientMessagingService
      .listen(JwtInfoClientMessageModel)
      .pipe(
        switchMap((jwtInfoMessage) =>
          this.store
            .dispatch(new UserStateActions.SetJwtInfo(jwtInfoMessage.data!))
            .pipe(switchMap(() => this.tryToMakeRequestWithNewBearerToken(request, next))),
        ),
      );
  }

  private tryToMakeRequestWithNewBearerToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | never {
    return next.handle(this.addBearerToken(request));
  }
}
