import { ChangeDetectorRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { SheetComponentModel } from '@hesti/services/sheet/sheet-component.model';

@Directive({
  standalone: true,
  selector: '[haSheetHost]',
})
export class SheetHostDirective implements OnInit {
  @Input() public sheetComponent: SheetComponentModel;

  public constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    const componentRef = this.viewContainerRef.createComponent(this.sheetComponent.component);
    componentRef.instance.data = this.sheetComponent.data;
    this.changeDetector.detectChanges();
  }
}
