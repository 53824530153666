import { COMMON_UK } from 'src/assets/i18n/common-translations/uk';

export const UK: PortalTranslations = {
  ...COMMON_UK,
  portalGeneral: {
    openInNewTab: 'Відкрити в новій вкладці',
    poweredBy: 'За підтримки',
    disabledThemeToggleTooltip: 'Будь ласка, закрийте налаштування оформлення, щоб змінити тему',
  },
  appearance: {
    title: 'Оформлення',
    light: 'Світлий',
    dark: 'Темний',
    switchingMode: 'Перемикання режиму',
    appearanceHasBeenSuccessfullySaved: 'Оформлення успішно збережено',
    automaticThemeModeInfo: 'Автоматичний режим змінює тему на основі налаштувань браузера.',
    manualThemeModeInfo: 'Ручний режим дозволяє користувачам вибрати тему в шапці порталу.',
    name: 'Назва',
    general: 'Загальне',
    scroll: 'Прокрутка',
    spinner: 'Спіннер',
    header: 'Шапка',
    footer: 'Підвал',
    palette: 'Палітра',

    fontColor: 'Шрифт',
    secondaryFontColor: 'Допоміжний шрифт',
    bodyBackgroundColor: 'Фон',
    dividerColor: 'Роздільник',
    boxShadowColor: 'Тінь',

    scrollThumbBackgroundColor: 'Прокрутка',
    scrollThumbHoverBackgroundColor: 'Прокрутка при наведенні',

    headerBackgroundColor: 'Шапка',
    headerFontColor: 'Шрифт шапки',
    headerBorderBottomColor: 'Межа шапки',

    spinnerColor: 'Спіннер',

    footerBackgroundColor: 'Підвал',
    footerFontColor: 'Шрифт підвала',
    footerBorderTopColor: 'Межа підвала',

    primaryColor: 'Основний',
    accentColor: 'Акцент',
    warnColor: 'Попередження',
    themeHasBeenSuccessfullyDeleted: 'Тему успішно видалено',
  },
  property: {
    editProperty: 'Редагувати нерухомість',
    allPhoto: 'Подивитись все',
    numberOfRoomsSingular: 'Кількість кімнат',
    numberOfRoomsPlural: 'Кількість кімнат',
    showButton: 'Показати все',
    hideButton: 'Приховати',
    details: 'Подробиці',

    commonInfo: 'Загальна інформація',
    media: 'Медіа',
    safety: 'Безпека',
    furnitureAndAppliances: 'Меблі та техніка',
    house: 'Будинок',
    building: 'будівля',
  },
  companyLogo: {
    logo: 'Логотип',
    companyLogoHasBeenSuccessfullyUpdated: 'Логотип компанії успішно оновлено',
    companyLogoInfo: 'Рекомендоване розширення - <b>.png з прозорим фоном</b>.',
  },
};
