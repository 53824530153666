import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { SnackBarService } from '@hesti/services/snack-bar/snack-bar.service';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { ApiErrorDtoModel } from '@hesti/models/api-error/dto/api-error.dto-model';
import { TranslateService } from '@ngx-translate/core';
import { errorCodeTranslations } from '@hesti/constants/error-code-translations.const';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { BaseSnackBarType } from '@hesti/components/snack-bar-templates/base-snack-bar/base-snack-bar-type.enum';

@Injectable()
export class GlobalErrorHandlerInterceptor implements HttpInterceptor {
  public constructor(
    private readonly snackBarService: SnackBarService<CommonTranslationKey>,
    private readonly translateService: TranslateService,
    private readonly zone: NgZone,
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(this.handleError));
  }

  private handleError = (response: HttpErrorResponse): never => {
    if (response.error?.errors?.[0]) {
      const errors: ApiErrorModel[] = response.error.errors.map((error: ApiErrorDtoModel) => new ApiErrorModel(error));
      this.showErrorBar(errors[0]);
    } else {
      const errorMessageKey = errorCodeTranslations[response.status as ErrorCode] || errorCodeTranslations[ErrorCode.SomethingWentWrong];
      this.zone.run(() => {
        this.snackBarService.showMessage(BaseSnackBarType.Error, errorMessageKey);
      });
    }
    throw response;
  };

  private showErrorBar(error: ApiErrorModel): void {
    const entity = error.entityKey ? `${this.translateService.instant(error.entityKey)}: ` : '';
    const message = `${entity}${this.translateService.instant(error.messageKey)}`;
    this.zone.run(() => {
      this.snackBarService.showTranslatedMessage(BaseSnackBarType.Error, message);
    });
  }
}
