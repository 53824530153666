import { MatDialogConfig } from '@angular/material/dialog';

export class DialogConst {
  public static readonly DefaultOptions: MatDialogConfig = {
    enterAnimationDuration: 173,
    exitAnimationDuration: 173,
    maxWidth: 1280,
    panelClass: 'ha-dialog-panel',
    backdropClass: 'ha-dialog-backdrop',
  };
  public static readonly DemoResultWidth = '333px';
}
