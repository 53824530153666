import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { PL } from '../../../assets/i18n/pl';
import { UK } from '../../../assets/i18n/uk';
import { EN } from '../../../assets/i18n/en';
import { RU } from '../../../assets/i18n/ru';

const translationsMap: Record<PortalLanguage, PortalTranslations> = {
  [PortalLanguage.PL]: PL,
  [PortalLanguage.UK]: UK,
  [PortalLanguage.EN]: EN,
  [PortalLanguage.RU]: RU,
};

export class StaticImportTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<unknown> {
    return of(translationsMap[lang as PortalLanguage]);
  }
}
