export const COMMON_PL: CommonTranslations = {
  api: {
    commonError: {
      badRequest: 'Nieprawidłowe żądanie',
      notFound: 'Żądany zasób nie został znaleziony',
      unauthorized: 'Musisz się zalogować',
      forbidden: 'Niestety nie masz uprawnień do wykonania tej czynności',
      internalServerError: 'Wewnętrzny błąd serwera',
      somethingWentWrong: 'Ups! Coś poszło nie tak :(',
      emailOrPasswordIncorrect: 'Email lub hasło jest nieprawidłowe',
      emailIsNotConfirmed: 'Email nie jest potwierdzony',
      invalidEmailConfirmationToken: 'Prośba o potwierdzenie nie istnieje lub wygasła. Proszę ponownie poprosić o potwierdzenie',
      invalidResetPasswordToken: 'Prośba o zresetowanie hasła nie istnieje lub wygasła. Proszę ponownie poprosić o zresetowanie hasła',
      unknownLanguage: 'Nieznany język',
      currentPasswordIncorrect: 'Aktualne hasło jest nieprawidłowe',
      changePasswordLocked: 'Były 3 nieudane próby zmiany hasła. Proszę poczekać 15 minut i spróbować ponownie',
      entityHasBeenModified: 'Wpis został już zmieniony. Odśwież stronę i spróbuj ponownie',
      roleDoesNotExist: 'Rola nie istnieje',
      registrationInvitationLockout: 'Zaproszenie do rejestracji jest zablokowane. Proszę poczekać 5 minut i spróbować ponownie',
      invalidRegistrationInvitationToken:
        'Zaproszenie do rejestracji nie istnieje lub wygasło. Skontaktuj się z administratorem, aby ponownie wysłać zaproszenie',
      changeEmailLockout: 'Zmiana adresu email jest zablokowana. Proszę poczekać 5 minut i spróbować ponownie',
      incorrectChangeEmailToken: 'Prośba o zmianę adresu email nie istnieje lub wygasła. Proszę ponownie poprosić o zmianę adresu email',
      incorrectNewEmail: 'Adres email różni się od adresu email, na który została wysłana prośba',
      descriptionHasImage: 'Opis jest używany w grupie zdjęć',
      addressIsNotFound: 'Żądany adres nie został znaleziony',
      userHasBeenArchived: 'Użytkownik został zarchiwizowany',
      userHasBeenDeleted: 'Użytkownik został usunięty',
      companyHasBeenArchived: 'Firma została zarchiwizowana',
      companyHasBeenDeleted: 'Firma została usunięta',
      userHasAcceptedInvitation: 'Użytkownik zaakceptował zaproszenie',
      userIsNotArchived: 'Użytkownik nie jest zarchiwizowany',
      themeCannotBeDeleted: 'Nie można usunąć motywu, który jest używany',
    },
    validationError: {
      invalidLongitude: 'Nieprawidłowa długość geograficzna',
      invalidLatitude: 'Nieprawidłowa szerokość geograficzna',
      invalidValue: 'Niewłaściwa wartość',
      required: 'Wymagane do wypełnienia',
      incorrectEmailFormat: 'Zły format wiadomości email',
      incorrectPasswordFormat: 'Nieprawidłowy format hasła',
      passwordConfirmationDoesntMatch: 'Potwierdzenie nie pasuje',
      incorrectUrlFormat: 'Nieprawidłowy format adresu URL',
      maxLength: 'Przekroczono maksymalną liczbę znaków ({{currentLength}}/{{maxLength}})',
      maxLengthApiVersion: 'Dozwolona liczba znaków została przekroczona',
      maxLengthShort: '{{currentLength}}/{{maxLength}}',
      emailIsAlreadyTaken: 'Ten email jest już zarejestrowany',
      incorrectFileFormat: 'Nieprawidłowy format pliku',
      fileSizeExceedsMaxSize: 'Rozmiar pliku przekracza maksymalny rozmiar',
      fileNameSizeExceedsMaxSize: '{{fileName}}: rozmiar pliku przekracza {{size}} Mb',
      fileIsEmpty: 'Plik jest pusty',
      incorrectPhoneFormat: 'Numer telefonu jest nieprawidłowy',
      incorrectJobTitle: 'Tytuł pracy jest nieprawidłowy',
      maximumNumberOfElementsExceeded: 'Przekroczono maksymalną liczbę elementów',
      thereIsDuplicateEmailInTheList: 'W liście jest duplikat adresu email',
      invalidCodeFormat: 'Nieprawidłowy format kodu',
      max: 'Maksymalna wartość to {{max}}',
      min: 'Minimalna wartość to {{min}}',
      minStrict: 'Wartość powinna być większa niż {{min}}',
      maxApiVersion: 'Wartość większa niż maksymalna',
      minApiVersion: 'Wartość mniejsza niż minimalna',
      mustBeInteger: 'Wartość powinna być liczbą całkowitą',
      lessOrEqualThan: 'Wartość powinna być mniejsza lub równa {{comparedNumber}}',
      subDomainIsAlreadyTaken: 'Ta subdomena jest już zarejestrowana',
      incorrectSubDomainFormat: 'Subdomena jest nieprawidłowa',
      dateThan: 'Data "Do" musi być późniejsza niż data "Od"',
      atLeastOneImageIsRequired: 'Wymagane jest co najmniej jedno zdjęcie',
      imageHasNotBeenUploaded: 'Zdjęcie nie zostało przesłane',
      onlyOneLogoIsAllowedPerTheme: 'Dozwolony jest tylko jeden logo na motyw',
    },
  },
  general: {
    language: 'Język',
    signIn: 'Zalogować się',
    search: 'Szukaj',
    back: 'Z powrotem',
    noResultsFound: 'Lista jest pusta',
    apply: 'Stosować',
    close: 'Zamknąć',
    clear: 'Wyczyść',
    openMail: 'Otwórz pocztę',
    startTypingHere: 'Zacznij pisać tutaj',
    create: 'Stworzyć',
    save: 'Zapisz',
    cancel: 'Anulować',
    edit: 'Edytować',
    refresh: 'Odśwież',
    ok: 'Ok',
    min: 'min.',
    sec: 'sek.',
    change: 'Zmiana',
    willBeUnlockedIn: 'Zostanie odblokowany za ',
    noResultsFor: 'Brak wyników dla "{{query}}"',
    privacyPolicy: 'Polityka Prywatności',
    termsOfService: 'Warunki Usługi',
    allRightsReserved: 'Wszelkie prawa zastrzeżone.',
    numberOfRooms: 'Liczba pokoi',
    price: 'Cena',
    pricePerSquareMeter: 'Cena za m²',
    deposit: 'Zastaw',
    utilityBill: 'Czynsz',
    parking: 'Parking',
    area: 'm²',
    areaLabel: 'Powierzchnia (m²)',
    landArea: 'Powierzchnia działki (m²)',
    floor: 'Piętro',
    numberOfFloors: 'Liczba pięter',
    address: 'Adres',
    searchAddress: 'Szukaj adresu',
    selectedAddress: 'Wybrany adres',
    addressIsNotSelected: 'Adres nie jest wybrany',
    approximateAddress: 'Przybliżony adres',
    country: 'Kraj',
    city: 'Miasto',
    street: 'Ulica',
    streetNumber: 'Numer ulicy',
    postalCode: 'Kod pocztowy',
    media: 'Media',
    internet: 'Internet',
    cableTv: 'Telewizja kablowa',
    telephone: 'Telefon',
    security: 'Bezpieczeństwo',
    antiBurglaryBlinds: 'Rolety antywłamaniowe',
    antiBurglaryDoorsWindows: 'Drzwi/okna antywłamaniowe',
    intercom: 'Domofon',
    videoMonitoring: 'Monitoring wideo',
    alarmSystem: 'System alarmowy',
    closedArea: 'Obszar zamknięty',
    equipment: 'Wyposażenie',
    furniture: 'Meble',
    washingMachine: 'Pralka',
    dishwasher: 'Zmywarka',
    refrigerator: 'Lodówka',
    fireplace: 'Kominek',
    oven: 'Piekarnik',
    cooker: 'Kuchenka',
    tvSet: 'Telewizor',
    airConditioning: 'Klimatyzacja',
    facilities: 'Udogodnienia',
    balcony: 'Balkon',
    loggia: 'Loggia',
    utilityRoom: 'Pomieszczenie gospodarcze',
    garageOrParkingPlace: 'Garaż lub miejsce parkingowe',
    basement: 'Piwnica',
    garden: 'Ogród',
    terrace: 'Taras',
    elevator: 'Winda',
    propertyCondition: 'Stan nieruchomości',
    heatingType: 'Typ ogrzewania',
    yearOfConstruction: 'Rok budowy',
    availableFrom: 'Dostępny od',
    description: 'Opis',
    location: 'Lokalizacja',
    setPinOnCenter: 'Ustaw pinezkę na środku',
    delete: 'Usuwać',
    addDescription: 'Dodaj opis',
    specify: 'Wskazać',
    addPhotoGroup: 'Dodaj grupę zdjęć',
    keyword: 'Słowo kluczowe',
    from: 'Z',
    to: 'Do',
    archive: 'Archiwizować',
    restore: 'Przywrócić',
    showTheList: 'Pokaż listę',
    longitude: 'Długość geograficzna',
    latitude: 'Szerokość geograficzna',
    photoGroup: 'Grupa zdjęć',
    photo: 'Zdjęcie',
    fileHasNotBeenSavedYet: 'Plik nie został jeszcze zapisany',
    warning: 'Ostrzeżenie',
    maxFileSize: 'Maks. {{size}} Mb',
    noResultsForQueryInThisArea: 'Brak wyników dla "{{query}}" w tej okolicy',
    noResultsFoundInThisArea: 'Brak wyników w tej okolicy',
    allPhoto: 'Widzieć wszystko',
    backToList: 'Powrót do listy',
    propertyNotFound: 'Nieruchomość nie została znaleziona',
    showOnPortal: 'Pokaż na portalu',
    author: 'Autor',
    offerType: 'Typ oferty',
    currency: 'Waluta',
    marketType: 'Typ rynku',
    propertyType: 'Typ nieruchomości',
    detailedInformation: 'Szczegółowe informacje',
    languageSkills: 'Umiejętności językowe',
  },
  properties: {
    create: 'Utwórz',
    sort: {
      title: 'Sortować',
      updateAtOption: 'Data aktualizacji',
      titleOption: 'Tytuł',
      areaOption: 'Powierzchnia',
    },
    filters: {
      title: 'Filtry',
      resetFilters: 'Resetować',
    },
    showList: 'Pokaż listę',
  },
  paginator: {
    itemsPerPage: 'Elementy na stronie:',
    firstPage: 'Pierwsza strona',
    lastPage: 'Ostatnia strona',
    nextPage: 'Następna strona',
    previousPage: 'Poprzednia strona',
    of: 'na',
  },
  portalLanguages: {
    PL: 'Polski',
    EN: 'English',
    RU: 'Русский',
    UK: 'Українська',
  },
  jobTitles: {
    Founder: 'Założyciel',
    CoFounder: 'Współzałożyciel',
    CEO: 'CEO',
    CFO: 'CFO',
    COO: 'COO',
    Director: 'Dyrektor',
    ViceDirector: 'Wicedyrektor',
    Manager: 'Menedżer',
    Researcher: 'Badacz',
    Agent: 'Agent',
    Broker: 'Broker',
    Investor: 'Inwestor',
    Appraiser: 'Rzeczoznawca',
    Consultant: 'Konsultant',
    Contractor: 'Wykonawca',
    Inspector: 'Inspektor',
    Clerk: 'Urzędnik',
    Analyst: 'Analityk',
    Financier: 'Finansista',
    Marketer: 'Marketer',
    Administrator: 'Administrator',
    Assistant: 'Asystent',
    Specialist: 'Specjalista',
    Expert: 'Ekspert',
    Realtor: 'Pośrednik',
  },
  passwordRules: {
    length: 'Minimum 8 znaków',
    upperCase: 'Dwie wielkie litery (A-Z)',
    lowerCase: 'Trzy małe litery (a-z)',
    numerals: 'Dwie liczby (0-9)',
    specialCharacter: 'Jeden znak specjalny (!@#$&*)',
    allowedCharacters: 'Tylko dozwolone znaki (A-Z, a-z, 0-9, !@#$&*)',
  },
  marketTypes: {
    PrimaryMarket: 'Pierwotny rynek',
    SecondaryMarket: 'Rynek wtórny',
  },
  offerTypes: {
    ForRent: 'Do wynajęcia',
    ForSale: 'Na sprzedaż',
  },
  propertyTypes: {
    Apartment: 'Mieszkanie',
    Room: 'Pokój',
    House: 'Dom',
    Garage: 'Garaż',
    CommercialPremises: 'Lokal użytkowy',
    Land: 'Działka',
  },
  heatingTypes: {
    NoHeating: 'Brak ogrzewania',
    CentralHeating: 'Ogrzewanie centralne',
    ElectricHeating: 'Ogrzewanie elektryczne',
    FloorHeating: 'Ogrzewanie podłogowe',
    GasHeating: 'Ogrzewanie gazowe',
    HeatPump: 'Pompa ciepła',
    StoveHeating: 'Ogrzewanie piecowe',
    BoilerHeating: 'Ogrzewanie kotłowe',
    Other: 'Inne',
  },
  propertyConditions: {
    RoughFinish: 'Szorstkie wykończenie',
    FineFinish: 'Dokładne wykończenie',
    RenovationWithoutFurniture: 'Remont bez mebli',
    RenovationWithFurniture: 'Remont z meblami',
  },
  shortcuts: {
    esc: 'Esc',
    arrowLeft: '←',
    arrowRight: '→',
    shiftQ: 'Alt + Shift + Q',
    shiftN: 'Alt + Shift + N',
    shiftS: 'Alt + Shift + S',
    shiftF: 'Alt + Shift + F',
  },
  loading: 'Ładowanie...',
  viewModes: {
    MapList: 'Mapa z listą',
    Map: 'Mapa',
  },
  deleteDialog: {
    info: 'Wpisz słowo kluczowe "{{keyword}}", aby usunąć.',
  },
  countries: {
    AF: 'Afganistan',
    AL: 'Albania',
    DZ: 'Algieria',
    AD: 'Andora',
    AO: 'Angola',
    AG: 'Antigua i Barbuda',
    AR: 'Argentyna',
    AM: 'Armenia',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbejdżan',
    BS: 'Bahamy',
    BH: 'Bahrajn',
    BD: 'Bangladesz',
    BB: 'Barbados',
    BY: 'Białoruś',
    BE: 'Belgia',
    BZ: 'Belize',
    BJ: 'Benin',
    BT: 'Bhutan',
    BO: 'Boliwia',
    BA: 'Bośnia i Hercegowina',
    BW: 'Botswana',
    BR: 'Brazylia',
    BN: 'Brunei',
    BG: 'Bułgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CI: 'Wybrzeże Kości Słoniowej',
    CV: 'Republika Zielonego Przylądka',
    KH: 'Kambodża',
    CM: 'Kamerun',
    CA: 'Kanada',
    CF: 'Republika Środkowoafrykańska',
    TD: 'Czad',
    CL: 'Chile',
    CN: 'Chiny',
    CO: 'Kolumbia',
    KM: 'Komory',
    CG: 'Kongo',
    CR: 'Kostaryka',
    HR: 'Chorwacja',
    CU: 'Kuba',
    CY: 'Cypr',
    CZ: 'Czechy',
    CD: 'Demokratyczna Republika Konga',
    DK: 'Dania',
    DJ: 'Dżibuti',
    DM: 'Dominika',
    DO: 'Dominikana',
    EC: 'Ekwador',
    EG: 'Egipt',
    SV: 'Salwador',
    GQ: 'Gwinea Równikowa',
    ER: 'Erytrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Etiopia',
    FJ: 'Fidżi',
    FI: 'Finlandia',
    FR: 'Francja',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Gruzja',
    DE: 'Niemcy',
    GH: 'Ghana',
    GR: 'Grecja',
    GD: 'Grenada',
    GT: 'Gwatemala',
    GN: 'Gwinea',
    GW: 'Gwinea Bissau',
    GY: 'Gujana',
    HT: 'Haiti',
    VA: 'Watykan',
    HN: 'Honduras',
    HU: 'Węgry',
    IS: 'Islandia',
    IN: 'Indie',
    ID: 'Indonezja',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Irlandia',
    IL: 'Izrael',
    IT: 'Włochy',
    JM: 'Jamajka',
    JP: 'Japonia',
    JO: 'Jordania',
    KZ: 'Kazachstan',
    KE: 'Kenia',
    KI: 'Kiribati',
    KW: 'Kuwejt',
    KG: 'Kirgistan',
    LA: 'Laos',
    LV: 'Łotwa',
    LB: 'Liban',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libia',
    LI: 'Liechtenstein',
    LT: 'Litwa',
    LU: 'Luksemburg',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malezja',
    MV: 'Malediwy',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Wyspy Marshalla',
    MR: 'Mauretania',
    MU: 'Mauritius',
    MX: 'Meksyk',
    FM: 'Mikronezja',
    MD: 'Mołdawia',
    MC: 'Monako',
    MN: 'Mongolia',
    ME: 'Czarnogóra',
    MA: 'Maroko',
    MZ: 'Mozambik',
    MM: 'Mjanma',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Holandia',
    NZ: 'Nowa Zelandia',
    NI: 'Nikaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    KP: 'Korea Północna',
    MK: 'Macedonia Północna',
    NO: 'Norwegia',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestyna',
    PA: 'Panama',
    PG: 'Papua-Nowa Gwinea',
    PY: 'Paragwaj',
    PE: 'Peru',
    PH: 'Filipiny',
    PL: 'Polska',
    PT: 'Portugalia',
    QA: 'Katar',
    RO: 'Rumunia',
    RU: 'Rosja',
    RW: 'Rwanda',
    KN: 'Saint Kitts i Nevis',
    LC: 'Saint Lucia',
    VC: 'Saint Vincent i Grenadyny',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Wyspy Świętego Tomasza i Książęca',
    SA: 'Arabia Saudyjska',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seszele',
    SL: 'Sierra Leone',
    SG: 'Singapur',
    SK: 'Słowacja',
    SI: 'Słowenia',
    SB: 'Wyspy Salomona',
    SO: 'Somalia',
    ZA: 'Republika Południowej Afryki',
    KR: 'Korea Południowa',
    SS: 'Sudan Południowy',
    ES: 'Hiszpania',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Surinam',
    SE: 'Szwecja',
    CH: 'Szwajcaria',
    SY: 'Syria',
    TJ: 'Tadżykistan',
    TZ: 'Tanzania',
    TH: 'Tajlandia',
    TL: 'Timor Wschodni',
    TG: 'Togo',
    TO: 'Tonga',
    TT: 'Trynidad i Tobago',
    TN: 'Tunezja',
    TR: 'Turcja',
    TM: 'Turkmenistan',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraina',
    AE: 'Zjednoczone Emiraty Arabskie',
    GB: 'Wielka Brytania',
    US: 'Stany Zjednoczone',
    UY: 'Urugwaj',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Wenezuela',
    VN: 'Wietnam',
    YE: 'Jemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  currencies: {
    USD: '$',
    EUR: '€',
    UAH: '₴',
    CZK: 'Kč',
    PLN: 'zł',
  },
  unsavedChanges: {
    title: 'Niezapisane zmiany',
    question: 'Masz niezapisane zmiany. Co chcesz zrobić?',
    cancel: 'Zostań',
    leave: 'Opuścić',
    discard: 'Odrzuć',
    save: 'Zapisz',
  },
  themeModes: {
    Light: 'Jasny',
    Dark: 'Ciemny',
    Browser: 'Automatyczny',
    Select: 'Ręczny',
  },
};
