import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpinnerConst } from '@hesti/constants/spinner.const';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { DomSelectorConst } from '@portal/constants/dom-selector.const';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterScrollService } from '@hesti/services/router-scroll/router-scroll.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ApiErrorComponent } from '@portal/components/api-error/api-error.component';
import { SheetComponent } from '@hesti/components/sheet/sheet.component';
import { SpinnerComponent } from '@hesti/components/spinner/spinner.component';
import { ClientMessagingService } from '@hesti/services/client-messaging/client-messaging.service';
import { JwtInfoClientMessageModel } from '@hesti/models/client-messaging/jwt-info-client-message.model';
import { PortalReadinessClientMessageModel } from '@hesti/models/client-messaging/portal-readiness-client-message.model';
import { UserStateActions } from '@hesti/store/user/user.state.actions';
import { CompanyInfoState } from '@portal/store/company-info/company-info.state';
import { PortalTranslatePipe } from '@portal/pipes/translate/portal-translate.pipe';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  standalone: true,
  selector: DomSelectorConst.AppRootTagName,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ApiErrorComponent, RouterOutlet, SpinnerComponent, SheetComponent, AsyncPipe, PortalTranslatePipe],
})
export class AppComponent {
  public readonly apiError$: Observable<ApiErrorModel | undefined> = this.store.select(CompanyInfoState.apiError);

  public readonly SpinnerConst = SpinnerConst;

  public constructor(
    private readonly routerScrollService: RouterScrollService,
    private readonly store: Store,
    private readonly clientMessagingService: ClientMessagingService,
  ) {
    this.routerScrollService.scrollToTopOnRouterChange();
    this.initClientMessaging();
  }

  private initClientMessaging(): void {
    this.clientMessagingService.init(window.parent, environment.adminUrl);
    this.clientMessagingService
      .listen(JwtInfoClientMessageModel)
      .pipe(untilDestroyed(this))
      .subscribe((jwtInfoMessage) => {
        this.store.dispatch(new UserStateActions.SetJwtInfo(jwtInfoMessage.data!));
      });
    this.clientMessagingService.sendMessage(new PortalReadinessClientMessageModel(true));
  }
}
