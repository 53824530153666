import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseClientMessageModel } from '@hesti/models/client-messaging/base-client-message.model';

type MessageConstructor<T extends BaseClientMessageModel> = new (...args: any[]) => T;

@Injectable({ providedIn: 'root' })
export class ClientMessagingService implements OnDestroy {
  private data$ = new Subject<BaseClientMessageModel>();

  protected targetWindow: Window;
  protected targetOrigin: string;

  private get isInitialized(): boolean {
    return !!this.targetWindow && !!this.targetOrigin;
  }

  public constructor(private readonly ngZone: NgZone) {
    window.addEventListener('message', this.receiveMessage);
  }

  public init(targetWindow: Window, targetOrigin: string): void {
    this.targetWindow = targetWindow;
    this.targetOrigin = targetOrigin;
  }

  public ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessage);
  }

  public sendMessage<T extends BaseClientMessageModel>(data: T): void {
    if (!this.isInitialized) {
      return;
    }

    this.targetWindow.postMessage(data, this.targetOrigin);
  }

  public listen<T extends BaseClientMessageModel>(messageConstructor: MessageConstructor<T>): Observable<T> {
    return this.data$.asObservable().pipe(filter((x): x is T => x.type === new messageConstructor().type));
  }

  private receiveMessage = (event: MessageEvent): void => {
    if (!this.isInitialized) {
      return;
    }

    if (event.origin !== this.targetOrigin || event.source !== this.targetWindow) {
      return;
    }

    this.ngZone.run(() => this.data$.next(event.data));
  };
}
